// WaGen___HASHKEY__91e7c782_2025-03-10 15:40:04 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
标准CRUD方法：添加记录
*/
export function kfTzxx_add(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：jgbm : Stringyhbh : Stringtzxxid : String
*/
export function kfTzxx_update(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：jgbm : Stringyhbh : Stringtzxxid : String
*/
export function kfTzxx_remove(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function kfTzxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function kfTzxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function kfTzxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：jgbm : Stringyhbh : Stringtzxxid : String
*/
export function kfTzxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function kfTzxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function kfTzxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //KfTzxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function kfTzxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function kfTzxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //KfTzxx
		}
	})
}

/* ---
未提供注释
*/
export function kfTzxx_updateKfTzxxYd(tzxxId, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/updateKfTzxxYd',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				tzxxId : tzxxId //String
			}
		}
	})
}

/* ---
多记录插入
*/
export function kfTzxx_insertList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<KfTzxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：jgbm : Stringyhbh : Stringtzxxid : String
*/
export function kfTzxx_updateList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<KfTzxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：jgbm : Stringyhbh : Stringtzxxid : String
*/
export function kfTzxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/KfTzxx/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<KfTzxx>
		}
	})
}

